.mobile-hidden {
  @media (max-width: 575px) {
    display: none;
  }
}

.desktop-hidden {
  @media (min-width: 576px) {
    display: none;
  }
}

.newBikeCarousal {
  margin: 2em;
  

  @media (max-width: 599px) {
    margin-bottom: 25px;
  }
  >.slick-slider .slick-dots{
    >li{
      width: auto;
      height: auto;
      margin: -5px 5px;
      @media(max-width: 699px) {
        margin: 0px 2px;
        }

      .buttonStyle{
        width: 16px;
        height: 16px;
        border-radius: 14px;
        background: rgba(255, 255, 255, 0.30);
        backdrop-filter: blur(2px);

        @media(max-width: 699px) {
          width: 8px;
          height: 8px;
          }
      }
      }
      >li.slick-active {
        .buttonStyle{
          width: 24px;
          background: #FFF;
          @media(max-width: 699px) {
            width: 14px;
            }
        }
    }
}

@media(max-width: 699px) {
  // margin-top: -58px;
  }

}