.aboutPage{
    background-color: #F6F7F9;
    padding: 43px;
    @media (max-width:899px) {
       text-align: center;

    }
    .aboutus-header{
        text-align: left;
        margin: 0px;
        @media (max-width:899px) {
            display: none;
        }
    }
    & .bg-container-content{
        display: flex;
        justify-content: space-between;
        text-align: left;
        & .mobile-about-us-heading{
            display: none;
            text-align: center;
            margin-bottom: 14px;
            @media (max-width:899px) {
                display: block;
                
            }
        }
        @media (max-width:899px) {
            flex-direction: column-reverse;
            text-align: center;
        }
        
        > p{
            background-color: white;
            padding: 30px;
            border-radius: 23px;
            margin-right: 60px;
        }
        
        > p{
            min-width: 40vw;
        }
         img{
            max-width: 40vw;
        }
        @media (max-width:899px) {
            > p{
                max-width: 90vw;
                margin-right: 0px;
                margin-bottom: 60px;
            }
             img{
                max-width: 90vw;
            }
        }
        
    }
}