.otp-input {
  justify-content: center;
  margin-top: 20px;

  .otp-input-box {
    background-color: #ffffff;
    width: 50px;
    height: 50px;
    font-size: 16px;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #D9D9D9;
    color: #3D3D3D;

    &:not(:last-child) {
      margin-right: 15px !important;
    }

    &:focus {
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.15);
      border: solid 1px rgba(51, 27, 80, 0.63);
      background-color: #ffffff;
      outline: none;
    }
  }
}

.otp-resend {
  display: flex;
  justify-content: center;

  .resend-button {
    border: none;
    margin-top: 15px;
    background-color: #ffffff;
    font-style: 14px;
    color: #050505;
    font-family: 'ProximaNovaRegular';
  }
}