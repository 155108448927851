
#bootstrap-input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
#bootstrap-input[type=number] {
  -moz-appearance: textfield;
}
.custom-select{
    border-radius: 4px;
    font-size: 16px;
    width:100%;
    max-width: 500px;
    margin-top: 0.8em;
      >div{
        background-color: #F6F7F9;
        border: none;
      }
}
