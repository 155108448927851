.bg-container-thank{
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    .container-card-thank{
        max-width: 50vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        padding: 3em;
    }
}
