.bg-container-footer{
    padding: 80px 60px 30px 60px;
    @media (max-width:899px) {
        padding: 40px 30px 15px 30px;;
    }
    @media (max-width:599px) {
        padding: 20px 15px 7.5px 15px;;
    }
    
    background: #FFFFFF;

    .bg-card-top-footer{
        display: flex;
        justify-content: space-between;
        @media (max-width:899px) {
            flex-direction: column;
        }
        h6,p{
            text-align: start;
        }
        .exchange-column,about-column,middle-column,social-column{
            text-align: start;
        }
       
        & .middle-column{
            display: flex;
            justify-content: space-evenly;
            @media (max-width:899px) {
                justify-content: space-between;
                & .about-column{
                    width: 60%;
                }
                & .community-column{
                    width: 40%;
                }
            }
            flex: 1 1;
           
            
           
        }
    }
    .bg-card-bottom-footer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        & .card-right{
            display: flex;
            justify-content: space-between;
            h5{
                margin-right: 20px;
            }

        }
    }
    .social-column{
        & .container-icon{
            text-align: left;
            img{
                margin-right: 18px;
               
            }
        }
    }
}

