 .homepage-middle-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #404857;
    & .heading-title{
        padding: 8px 18px;
        border-bottom: 2px solid #1D68F4;
        width: fit-content;
    }
    & .bg-container-how{
        display: flex;
        padding: 3em;
        justify-content: center;
        background-color: white;
        margin-bottom: 20px;
        
       & .info-card-1{
        display: flex;
        text-align: left;
        max-width: 340px;
       > div{
        > h4{
            color: #083790;
        }
       }
       
    
        > h4{
            color: #083790;
            margin-right: 0.5em;
            font-size: 1.5em;
            margin-top: 0.6em;
        }
       }
       @media (max-width:899px) {
        flex-direction: column;
        >img{
            display: none;
        }
        }
    }
    & .bg-container-navigator{
        display: flex;
        justify-content: center;
        & .card-container-navigator{
            display: grid;
            grid-template-columns: auto auto;
            column-gap: 3em;
            justify-content: center;
            border-radius: 40px;
            padding: 1.7em;
            background-color: white;
            width: fit-content;
            img{
                width: 100%;
            }
            .nav-image-button{
                border: none;
                background: none;
                
            }

            @media (max-width:899px) {
                grid-template-columns: auto;
                row-gap: 3em;
            }
        }
    }   
}


