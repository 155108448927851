@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);
@media (max-width: 575px) {
  .mobile-hidden {
    display: none; } }

@media (min-width: 576px) {
  .desktop-hidden {
    display: none; } }

.customerFeedBack .col-sm-12 h2 {
  display: inline-block;
  padding: 10px 30px;
  margin-bottom: 50px;
  font-size: 1.5rem;
  font-weight: 500;
  color: #404857;
  border-bottom: 2px solid #1D68F4; }

.customerFeedBack .col-sm-12 .ratingCardConatainer {
  display: flex;
  padding: 0px 30px;
  margin-top: 0px 20px;
  scroll-behavior: smooth;
  overflow: scroll;
  margin: auto;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  -ms-overflow-style: none;
  scrollbar-width: none; }
  .customerFeedBack .col-sm-12 .ratingCardConatainer::-webkit-scrollbar {
    display: none; }
  .customerFeedBack .col-sm-12 .ratingCardConatainer .ratingCardMain {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 1;
    max-width: 320px;
    min-width: 280px;
    margin: 0px 1.25vw;
    padding: 16px 30px;
    border-radius: 23px;
    background: #FFF;
    box-shadow: 0px 3.00237px 12.00948px 6.00474px rgba(0, 0, 0, 0.04); }
    @media (max-width: 599px) {
      .customerFeedBack .col-sm-12 .ratingCardConatainer .ratingCardMain {
        min-width: 250px;
        margin: 0px 7px;
        padding: 12px 24px; } }
    .customerFeedBack .col-sm-12 .ratingCardConatainer .ratingCardMain .ratingCard_customerProfile {
      display: flex;
      align-items: center; }
      .customerFeedBack .col-sm-12 .ratingCardConatainer .ratingCardMain .ratingCard_customerProfile .ratingCard_customerInfo {
        margin-left: 20px;
        display: flex;
        height: 45px;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        grid-gap: 5px;
        gap: 5px; }
        .customerFeedBack .col-sm-12 .ratingCardConatainer .ratingCardMain .ratingCard_customerProfile .ratingCard_customerInfo span:first-child {
          font-size: 1.2em; }
        .customerFeedBack .col-sm-12 .ratingCardConatainer .ratingCardMain .ratingCard_customerProfile .ratingCard_customerInfo span:last-child {
          font-size: .8em; }
    .customerFeedBack .col-sm-12 .ratingCardConatainer .ratingCardMain > h1 {
      margin-bottom: -9px;
      font-weight: 600;
      margin-top: 10px; }
    .customerFeedBack .col-sm-12 .ratingCardConatainer .ratingCardMain > p {
      margin-bottom: 0;
      line-height: 100%;
      text-align: start;
      font-size: .8rem;
      line-height: 24.647px; }
    .customerFeedBack .col-sm-12 .ratingCardConatainer .ratingCardMain .starImg {
      width: 18px; }
      @media (max-width: 599px) {
        .customerFeedBack .col-sm-12 .ratingCardConatainer .ratingCardMain .starImg {
          width: 12px; } }
    @media (max-width: 599px) {
      .customerFeedBack .col-sm-12 .ratingCardConatainer .ratingCardMain {
        font-size: 14px; } }
  @media (max-width: 699px) {
    .customerFeedBack .col-sm-12 .ratingCardConatainer {
      padding: 0px 0px; } }

.faqSection {
  margin: 20px 40px 50px 40px; }
  @media (max-width: 599px) {
    .faqSection {
      margin: 20px; } }
  .faqSection h2 {
    display: inline-block;
    padding: 10px 30px;
    margin-bottom: 50px;
    font-size: 1.5rem;
    font-weight: 500;
    color: #5A5365;
    border-bottom: 2px solid #1D68F4; }

.faqNewBikeContainer {
  text-align: start; }
  .faqNewBikeContainer > .MuiAccordion-root {
    background: #FFF;
    color: #5A5365;
    overflow: hidden;
    box-shadow: none; }
    .faqNewBikeContainer > .MuiAccordion-root.Mui-expanded {
      margin: 0; }
    .faqNewBikeContainer > .MuiAccordion-root .MuiAccordionSummary-root {
      padding: 0px 12px; }
      .faqNewBikeContainer > .MuiAccordion-root .MuiAccordionSummary-root > .MuiAccordionSummary-content {
        color: #828282;
        font-weight: 500;
        font-size: 1.1em; }
      .faqNewBikeContainer > .MuiAccordion-root .MuiAccordionSummary-root .MuiAccordionSummary-expandIcon span:first-child {
        background-color: #5d9df0;
        border-radius: 50%; }
      .faqNewBikeContainer > .MuiAccordion-root .MuiAccordionSummary-root .MuiAccordionSummary-expandIcon.Mui-expanded span:first-child {
        background: #1D68F4; }
    .faqNewBikeContainer > .MuiAccordion-root > .MuiAccordionSummary-root.Mui-expanded {
      min-height: 47px !important; }
      .faqNewBikeContainer > .MuiAccordion-root > .MuiAccordionSummary-root.Mui-expanded > .MuiAccordionSummary-content.Mui-expanded {
        color: #1D68F4;
        font-weight: 700; }
    .faqNewBikeContainer > .MuiAccordion-root > .MuiCollapse-root > .MuiCollapse-wrapper > .MuiCollapse-wrapperInner > div {
      background-color: inherit !important; }
      .faqNewBikeContainer > .MuiAccordion-root > .MuiCollapse-root > .MuiCollapse-wrapper > .MuiCollapse-wrapperInner > div > .MuiAccordionDetails-root {
        font-size: 1em;
        color: #5A5365;
        line-height: 140%;
        background-color: inherit !important; }
  .faqNewBikeContainer > .MuiAccordion-root.Mui-expanded {
    background: #ECF3FC;
    -webkit-animation: expandAnimation 1s ease .15s;
            animation: expandAnimation 1s ease .15s; }

.homepage-middle-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #404857; }
  .homepage-middle-section .heading-title {
    padding: 8px 18px;
    border-bottom: 2px solid #1D68F4;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
  .homepage-middle-section .bg-container-how {
    display: flex;
    padding: 3em;
    justify-content: center;
    background-color: white;
    margin-bottom: 20px; }
    .homepage-middle-section .bg-container-how .info-card-1 {
      display: flex;
      text-align: left;
      max-width: 340px; }
      .homepage-middle-section .bg-container-how .info-card-1 > div > h4 {
        color: #083790; }
      .homepage-middle-section .bg-container-how .info-card-1 > h4 {
        color: #083790;
        margin-right: 0.5em;
        font-size: 1.5em;
        margin-top: 0.6em; }
    @media (max-width: 899px) {
      .homepage-middle-section .bg-container-how {
        flex-direction: column; }
        .homepage-middle-section .bg-container-how > img {
          display: none; } }
  .homepage-middle-section .bg-container-navigator {
    display: flex;
    justify-content: center; }
    .homepage-middle-section .bg-container-navigator .card-container-navigator {
      display: grid;
      grid-template-columns: auto auto;
      grid-column-gap: 3em;
      -webkit-column-gap: 3em;
         -moz-column-gap: 3em;
              column-gap: 3em;
      justify-content: center;
      border-radius: 40px;
      padding: 1.7em;
      background-color: white;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content; }
      .homepage-middle-section .bg-container-navigator .card-container-navigator img {
        width: 100%; }
      .homepage-middle-section .bg-container-navigator .card-container-navigator .nav-image-button {
        border: none;
        background: none; }
      @media (max-width: 899px) {
        .homepage-middle-section .bg-container-navigator .card-container-navigator {
          grid-template-columns: auto;
          grid-row-gap: 3em;
          row-gap: 3em; } }

@media (max-width: 575px) {
  .mobile-hidden {
    display: none; } }

@media (min-width: 576px) {
  .desktop-hidden {
    display: none; } }

.newBikeCarousal {
  margin: 2em; }
  @media (max-width: 599px) {
    .newBikeCarousal {
      margin-bottom: 25px; } }
  .newBikeCarousal > .slick-slider .slick-dots > li {
    width: auto;
    height: auto;
    margin: -5px 5px; }
    @media (max-width: 699px) {
      .newBikeCarousal > .slick-slider .slick-dots > li {
        margin: 0px 2px; } }
    .newBikeCarousal > .slick-slider .slick-dots > li .buttonStyle {
      width: 16px;
      height: 16px;
      border-radius: 14px;
      background: rgba(255, 255, 255, 0.3);
      -webkit-backdrop-filter: blur(2px);
              backdrop-filter: blur(2px); }
      @media (max-width: 699px) {
        .newBikeCarousal > .slick-slider .slick-dots > li .buttonStyle {
          width: 8px;
          height: 8px; } }
  .newBikeCarousal > .slick-slider .slick-dots > li.slick-active .buttonStyle {
    width: 24px;
    background: #FFF; }
    @media (max-width: 699px) {
      .newBikeCarousal > .slick-slider .slick-dots > li.slick-active .buttonStyle {
        width: 14px; } }

.aboutPage {
  background-color: #F6F7F9;
  padding: 43px; }
  @media (max-width: 899px) {
    .aboutPage {
      text-align: center; } }
  .aboutPage .aboutus-header {
    text-align: left;
    margin: 0px; }
    @media (max-width: 899px) {
      .aboutPage .aboutus-header {
        display: none; } }
  .aboutPage .bg-container-content {
    display: flex;
    justify-content: space-between;
    text-align: left; }
    .aboutPage .bg-container-content .mobile-about-us-heading {
      display: none;
      text-align: center;
      margin-bottom: 14px; }
      @media (max-width: 899px) {
        .aboutPage .bg-container-content .mobile-about-us-heading {
          display: block; } }
    @media (max-width: 899px) {
      .aboutPage .bg-container-content {
        flex-direction: column-reverse;
        text-align: center; } }
    .aboutPage .bg-container-content > p {
      background-color: white;
      padding: 30px;
      border-radius: 23px;
      margin-right: 60px; }
    .aboutPage .bg-container-content > p {
      min-width: 40vw; }
    .aboutPage .bg-container-content img {
      max-width: 40vw; }
    @media (max-width: 899px) {
      .aboutPage .bg-container-content > p {
        max-width: 90vw;
        margin-right: 0px;
        margin-bottom: 60px; }
      .aboutPage .bg-container-content img {
        max-width: 90vw; } }

.contactPage {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 20px;
  margin: 40px 70px;
  border-radius: 23px;
  background: #FFF; }
  @media (max-width: 899px) {
    .contactPage {
      flex-direction: column;
      background: none;
      margin: 0; } }
  @media (max-width: 899px) {
    .contactPage .imgContainer img {
      width: 100%;
      max-width: 400px; } }
  .contactPage .contactUsDetails {
    color: #848484;
    display: flex;
    flex-direction: column;
    grid-gap: 45px;
    gap: 45px; }
    .contactPage .contactUsDetails .detialsContainer {
      text-align: start; }
      @media (max-width: 899px) {
        .contactPage .contactUsDetails .detialsContainer {
          text-align: center; } }
    .contactPage .contactUsDetails .contactInfoContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      grid-gap: 45px;
      gap: 45px; }
      .contactPage .contactUsDetails .contactInfoContainer .infoContainer {
        display: flex;
        grid-gap: 12px;
        gap: 12px; }
        .contactPage .contactUsDetails .contactInfoContainer .infoContainer .info-box {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          text-align: start;
          grid-gap: 2px;
          gap: 2px; }
          .contactPage .contactUsDetails .contactInfoContainer .infoContainer .info-box .info-label {
            font-size: .8em; }
          .contactPage .contactUsDetails .contactInfoContainer .infoContainer .info-box .info {
            color: #000; }

body {
  margin: 0;
  padding: 0;
  font-family: Roboto;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #3A3A3A;
  text-align: left;
  background: #F6F7F9;
  font-size: 18px; }
  @media (max-width: 599px) {
    body {
      font-size: 14px; } }

a {
  text-decoration: none;
  color: #000; }

button {
  cursor: pointer; }

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

* {
  box-sizing: border-box; }

footer {
  background-color: white;
  min-height: 100px; }

.App-logo {
  height: 40vmin;
  pointer-events: none; }

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear; } }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.homePage {
  background-color: #F6F7F9;
  flex-grow: 1;
  align-content: center; }

header {
  background-color: #fff;
  padding: 10px;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  header .logoSearchContainer {
    display: flex;
    grid-gap: 50px;
    gap: 50px;
    justify-content: space-between; }
    @media (max-width: 899px) {
      header .logoSearchContainer {
        grid-gap: 0;
        gap: 0; } }
    header .logoSearchContainer .logo {
      width: 205px; }
      @media (max-width: 599px) {
        header .logoSearchContainer .logo {
          width: 130px; } }
    header .logoSearchContainer .selectCityBox {
      min-width: 100px;
      box-sizing: border-box;
      height: 40px;
      font-size: 1rem;
      padding: 8px 16px;
      border-radius: 40px;
      border: 1px solid #C6D0DC;
      background: #FFF;
      display: flex;
      justify-content: space-between;
      align-items: center;
      grid-gap: 10px;
      gap: 10px; }
      @media (max-width: 599px) {
        header .logoSearchContainer .selectCityBox {
          height: 32px; } }
      header .logoSearchContainer .selectCityBox select {
        all: unset; }
  @media (max-width: 899px) {
    header {
      flex-direction: column;
      align-items: stretch; } }

@media (max-width: 899px) {
  .navContainer::before {
    content: '';
    display: block;
    width: 80%;
    color: red;
    height: .5px;
    opacity: 0.4;
    border-top: .5px solid #CBD4F2;
    margin-bottom: 10px;
    margin-left: 10px; } }

.navContainer nav {
  display: flex;
  grid-gap: 24px;
  gap: 24px;
  margin-right: 50px; }
  @media (max-width: 899px) {
    .navContainer nav {
      justify-content: space-around;
      margin-right: 0; } }

.navLinks {
  cursor: pointer;
  min-width: 60px; }

.navLinks.active {
  font-weight: 600; }
  @media (max-width: 899px) {
    .navLinks.active {
      border-bottom: 2px solid #3563E9;
      padding-bottom: 5px; } }

.navLinks:hover {
  font-weight: 600;
  opacity: .5; }

.bg-container-footer {
  padding: 80px 60px 30px 60px;
  background: #FFFFFF; }
  @media (max-width: 899px) {
    .bg-container-footer {
      padding: 40px 30px 15px 30px; } }
  @media (max-width: 599px) {
    .bg-container-footer {
      padding: 20px 15px 7.5px 15px; } }
  .bg-container-footer .bg-card-top-footer {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 899px) {
      .bg-container-footer .bg-card-top-footer {
        flex-direction: column; } }
    .bg-container-footer .bg-card-top-footer h6, .bg-container-footer .bg-card-top-footer p {
      text-align: start; }
    .bg-container-footer .bg-card-top-footer .exchange-column, .bg-container-footer .bg-card-top-footer about-column, .bg-container-footer .bg-card-top-footer middle-column, .bg-container-footer .bg-card-top-footer social-column {
      text-align: start; }
    .bg-container-footer .bg-card-top-footer .middle-column {
      display: flex;
      justify-content: space-evenly;
      flex: 1 1; }
      @media (max-width: 899px) {
        .bg-container-footer .bg-card-top-footer .middle-column {
          justify-content: space-between; }
          .bg-container-footer .bg-card-top-footer .middle-column .about-column {
            width: 60%; }
          .bg-container-footer .bg-card-top-footer .middle-column .community-column {
            width: 40%; } }
  .bg-container-footer .bg-card-bottom-footer {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .bg-container-footer .bg-card-bottom-footer .card-right {
      display: flex;
      justify-content: space-between; }
      .bg-container-footer .bg-card-bottom-footer .card-right h5 {
        margin-right: 20px; }
  .bg-container-footer .social-column .container-icon {
    text-align: left; }
    .bg-container-footer .social-column .container-icon img {
      margin-right: 18px; }

#bootstrap-input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

/* Firefox */
#bootstrap-input[type=number] {
  -moz-appearance: textfield; }

.custom-select {
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  max-width: 500px;
  margin-top: 0.8em; }
  .custom-select > div {
    background-color: #F6F7F9;
    border: none; }

.container-card-input {
  padding: 2em;
  background-color: white;
  width: 80%;
  height: 100%;
  margin: 2em;
  border-radius: 0.6em;
  text-align: left; }
  .container-card-input p {
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    width: 80vw;
    max-width: 800px;
    margin: 2em;
    border-radius: 0.6em;
    text-align: left;
    min-width: 320px; }
  .container-card-input > p {
    font-size: 0.6em;
    margin: 0px;
    text-align: left;
    margin-bottom: 15px;
    color: #90A3BF; }
  .container-card-input h5 {
    margin: 0px;
    margin-bottom: 4px;
    text-align: left; }
  .container-card-input .exchange-button {
    background-color: #3563E9;
    font-size: 0.9em;
    padding: 0.8em 1.8em;
    color: white;
    border: none;
    border-radius: 7px;
    display: block;
    float: right;
    cursor: pointer; }
    .container-card-input .exchange-button.verifyButton {
      float: none;
      width: 100%; }
  .container-card-input .container-user-details {
    background-color: #F9FBFF;
    max-width: 40%;
    padding: 30px 56px 26px;
    margin: auto; }
    .container-card-input .container-user-details p {
      color: #313957;
      color: var(--Secondary-text, #313957);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      /* 22.4px */
      letter-spacing: 0.14px; }
  .container-card-input .display-two {
    display: flex;
    width: 100%; }
    @media (max-width: 599px) {
      .container-card-input .display-two {
        flex-direction: column; } }

.bg-container-inputForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80vh;
  margin-bottom: 50px; }

.otp-timer-conatiner {
  margin-bottom: 20px;
  min-height: 70vh; }

.otp-input {
  justify-content: center;
  margin-top: 20px; }
  .otp-input .otp-input-box {
    background-color: #ffffff;
    width: 50px;
    height: 50px;
    font-size: 16px;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #D9D9D9;
    color: #3D3D3D; }
    .otp-input .otp-input-box:not(:last-child) {
      margin-right: 15px !important; }
    .otp-input .otp-input-box:focus {
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.15);
      border: solid 1px rgba(51, 27, 80, 0.63);
      background-color: #ffffff;
      outline: none; }

.otp-resend {
  display: flex;
  justify-content: center; }
  .otp-resend .resend-button {
    border: none;
    margin-top: 15px;
    background-color: #ffffff;
    font-style: 14px;
    color: #050505;
    font-family: 'ProximaNovaRegular'; }

.bg-container-thank {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw; }
  .bg-container-thank .container-card-thank {
    max-width: 50vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 3em; }

.bg-container-price-calculator {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

