
.faqSection{
  margin: 20px 40px 50px 40px;

  @media(max-width: 599px) {
   margin: 20px
     }


  h2{
    display: inline-block;
    padding: 10px 30px;
    margin-bottom: 50px;
    font-size: 1.5rem;
    font-weight: 500;
    color: #5A5365;
    border-bottom: 2px solid #1D68F4;
  }
}
.faqNewBikeContainer{
  text-align: start;
  >.MuiAccordion-root{

    background: #FFF;
    color: #5A5365;
    overflow:hidden;
    box-shadow: none;
    &.Mui-expanded{
      margin: 0;
    }
    .MuiAccordionSummary-root{
      padding: 0px 12px;
      >.MuiAccordionSummary-content{
        color: #828282;
        font-weight: 500;
        font-size: 1.1em;
      }
      .MuiAccordionSummary-expandIcon{ 
        span:first-child{
        background-color:#5d9df0;
        border-radius: 50%;
       }
      }
      .MuiAccordionSummary-expandIcon.Mui-expanded{
        span:first-child{
          background: #1D68F4;
       }
      
      }
    }
    >.MuiAccordionSummary-root.Mui-expanded{
      min-height: 47px !important;
     >.MuiAccordionSummary-content.Mui-expanded{
      //  margin: 12px 0;
       color: #1D68F4;
      font-weight: 700;
     }
    }
    >.MuiCollapse-root{
      >.MuiCollapse-wrapper{
        >.MuiCollapse-wrapperInner{
          >div{
            background-color:inherit !important;
            >.MuiAccordionDetails-root{
              // background-color: none;
              font-size: 1em;
              color: #5A5365;
              line-height: 140%; 
              background-color:inherit !important;
            }
          }

        }
      }
    }
  }
  >.MuiAccordion-root.Mui-expanded
  {
    background: #ECF3FC;
    animation: expandAnimation 1s ease .15s;

    // @keyframes expandAnimation {
    //   0%{padding-bottom: 15px;}
    //   100%{padding-bottom: 10px;}
    // }
  }
  
}
