.mobile-hidden {
  @media (max-width: 575px) {
    display: none;
  }
}

.desktop-hidden {
  @media (min-width: 576px) {
    display: none;
  }
}

.customerFeedBack {

  .col-sm-12 {
    h2{
      display: inline-block;
      padding: 10px 30px;
      margin-bottom: 50px;
      font-size: 1.5rem;
      font-weight: 500;
      color: #404857;
      border-bottom: 2px solid #1D68F4;
    }
  .ratingCardConatainer{
    display: flex;
    padding: 0px 30px;
    margin-top: 0px 20px;
    scroll-behavior: smooth;
    overflow: scroll;
    margin: auto;
    max-width: fit-content;
    -ms-overflow-style: none; 
    scrollbar-width: none;
  
   &::-webkit-scrollbar{
    display: none;
  }


    .ratingCardMain{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1;
      max-width: 320px;
      min-width: 280px;
      margin: 0px 1.25vw;
      padding: 16px 30px;
      border-radius: 23px;
      background: #FFF;
      box-shadow: 0px 3.0023694038391113px 12.009477615356445px 6.004738807678223px rgba(0, 0, 0, 0.04);

      @media(max-width: 599px) {
        min-width: 250px;
        margin: 0px 7px;
        padding: 12px 24px;

         }
      .ratingCard_customerProfile{
        display: flex;
        align-items: center;
        .ratingCard_customerInfo{
          margin-left: 20px;
          display: flex;
          height: 45px;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          gap: 5px;

          span:first-child{
            font-size: 1.2em;
            // @media(max-width: 599px) {
            //  font-size: 1.2em;
            //   }
          }
          span:last-child{
            font-size: .8em;
            // @media(max-width: 599px) {
            //  font-size: 1em;
            //   }
          }
        }
      }

      >h1{
        margin-bottom: -9px;
        font-weight: 600;
        margin-top: 10px;
      }
      >p{
        margin-bottom: 0;
        line-height: 100%;
        text-align: start;
        font-size: .8rem;
        line-height: 24.647px
      }

      .starImg{
        width: 18px;
        @media(max-width: 599px) {
          width: 12px;
  
           }
      }
      @media(max-width: 599px) {
        font-size: 14px;
        }

     
    }

    @media(max-width: 699px) {
      padding: 0px 0px;
      }
  }
  }
}