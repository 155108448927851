.contactPage{
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 20px;
  margin: 40px 70px;
  border-radius: 23px;
  background: #FFF;

  @media (max-width: 899px) {
    flex-direction: column;
    background: none;
    margin: 0;
   
  }


  .imgContainer{

    img{
      @media (max-width: 899px) {
        width: 100%;
        max-width: 400px;
       
      }
    
    }
  }

  .contactUsDetails{

    color: #848484;
    display: flex;
    flex-direction: column;
    gap: 45px;

    .detialsContainer{
      text-align: start;
      @media (max-width: 899px) {
        text-align:center;
       
      }
   
    }
    .contactInfoContainer{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 45px;

      .infoContainer{
        display: flex;
        gap: 12px;

        .info-box{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          text-align: start;
          gap: 2px;
          
          .info-label{
            font-size: .8em;
          }
          .info{
            color: #000;
          }
        }
      }
    }

  }
}