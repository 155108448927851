header {
    background-color: #fff;
    padding: 10px;
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logoSearchContainer {
        display: flex;
        gap: 50px;
        justify-content: space-between;
        @media (max-width: 899px) {
           gap: 0;
          }

        .logo {
            width: 205px;
            @media (max-width: 599px) {
              width: 130px;
              }
        }

        .selectCityBox {
            min-width: 100px;
            // width: 148px;
            box-sizing: border-box;
            height: 40px;
            font-size: 1rem;
            padding: 8px 16px;
            border-radius: 40px;
            border: 1px solid #C6D0DC;
            background: #FFF;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            @media (max-width: 599px) {
                height: 32px;
                }

            select {
                all: unset

            }
        

        }
    }


  @media (max-width: 899px) {
    flex-direction: column;
    align-items: stretch;
  }
}


.navContainer{

    @media (max-width: 899px) {
       
    &::before{
        content: '';
        display: block;
        width: 80%;
        color: red;
        height: .5px;
        opacity: 0.4;
        border-top: .5px solid #CBD4F2;
        margin-bottom: 10px;
        margin-left: 10px;
    }
      }

    nav {
        display: flex;
        gap: 24px;
        // font-size: 18px;
        margin-right: 50px;
    
    
      @media (max-width: 899px) {
        justify-content: space-around;
        margin-right: 0;
      }
    
        // color: 000;
    }
    
}


.navLinks {
    cursor: pointer;
    min-width: 60px
}

.navLinks.active {
    font-weight: 600;
     
    @media (max-width: 899px) {
        border-bottom: 2px solid #3563E9;
        padding-bottom: 5px;
      }
 
}

.navLinks:hover {
    font-weight: 600;
    opacity: .5;
}