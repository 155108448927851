.container-card-input{
    padding: 2em;
    background-color: white;
    width: 80%;
    height: 100%;
    margin: 2em;
    border-radius: 0.6em;
    text-align: left;
    p{
    min-width: fit-content;
    width: 80vw;
    max-width: 800px;
    margin: 2em;
    border-radius: 0.6em;
    text-align: left;
    min-width: 320px;
    }
    >p{
        font-size: 0.6em;
        margin: 0px;
        text-align: left;
        margin-bottom: 15px;
        color: #90A3BF;
    }
    h5{
        margin: 0px;
        margin-bottom: 4px;
        text-align: left;
    }
    .exchange-button{
        background-color: #3563E9;
        font-size: 0.9em;
        padding: 0.8em 1.8em;
        color: white;
        border: none;
        border-radius: 7px;
        display: block;
        float: right;
        cursor: pointer;
        &.verifyButton{
            float: none;
            width: 100%;
        }
    }

    .container-user-details{
        background-color:#F9FBFF;
        max-width:40%;
        padding: 30px 56px 26px;
        margin: auto;

        p{
            color: var(--Secondary-text, #313957);
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 160%; /* 22.4px */
letter-spacing: 0.14px;
        }

    }
    .display-two{
        display: flex;
        width: 100%;
        // margin: 8px;
        @media (max-width:599px) {
            flex-direction: column;
        }
    }
    
}

.bg-container-inputForm{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80vh;
    margin-bottom: 50px;

}

.otp-timer-conatiner{
    margin-bottom: 20px;
    min-height: 70vh;
   
}